//let collageImages = document.querySelectorAll('.collage-image');

////const swapImages = (numToSwap, newImage) => {
////    document.querySelector(`.collage-image[data-index="${numToSwap}"]`).style.backgroundImage = newImage;
////}

//const beginSwapping = () => {
//    setTimeout(() => {
//        const imagesToSwap = document.querySelectorAll('.collage-image[data-category="toSwap"]');
//        const visibleImages = document.querySelectorAll('.collage-image[data-category="inPlace"]');
//        const swappedImages = document.querySelectorAll('.collage-image[data-category="swappedOut"]')
//        if (imagesToSwap.length > 0) {
//            const randSwap = Math.Floor(Math.random() * imagesToSwap.length);
//            const randInPlace = Math.Floor(Math.random() * visibleImages.length);

//            visibleImages[randInPlace].dataset.category = "swappedOut";
//            collageImages[randInPlace] = imagesToSwap[randSwap];
//            imagesToSwap[randSwap].dataset.category = "inPlace";
//        } else if (swappedImages.length > 0) {
//            swappedImages.forEach(el => {
//                el.dataset.category = "toSwap";
//            })
//        }
//    }, 500)
//}

//const initCollage = () => {
//    collageImages.forEach((el, i) => {
//        if (i < 8) {
//            el.style.display = "block";
//            el.dataset.visible = "true";
//            el.dataset.category = "inPlace"
//        } else {
//            el.style.display = "none";
//            el.dataset.visible = "false";
//            el.dataset.category = "toSwap"
//        }
//    })
//    if (collageImages.length > 8) {
//        beginSwapping();
//    }
    
//}

//if (collageImages) {
//    document.addEventListener('DOMContentLoaded', () => { initCollage() })
//}
