
if (document.querySelector('.blog-article')) {
    //Paged items
    var BlogHolder = document.querySelector(".tiles");
    var BlogBtn = document.querySelector(".js-load-more");
    let featuredArticle = document.querySelector(".featured");

    async function getMoreArticles(pageNumber = 0, amount = 9, category = "all", append = false) {
        const data = await fetch(`/Blog/MoreArticles/${pageNumber}/${category}/${amount}`);

        if (data.ok) {
            const results = await data.text();
            /*            console.log(results);*/
            if (append) {
                BlogHolder.innerHTML += results;
            } else {
                BlogHolder.innerHTML = results;
            }

            if (BlogHolder.innerHTML.trim() == '') {
                BlogHolder.innerHTML = "<h3 style='grid-column:1/4; text-align:center; '>Sorry, no articles found</h3>";
            }
            if (results.trim() == "") {
                BlogBtn.style.opacity = .5;
                BlogBtn.disabled = true;
            } else {
                BlogBtn.style.opacity = 1;
                BlogBtn.disable = false;
            }
            BlogBtn.setAttribute("data-page", parseInt(BlogBtn.getAttribute("data-page")) + 1);
            BlogBtn.removeAttribute("disabled");
        }

        const articles = document.querySelectorAll('.tiles .blog-article');

        //if (pageNumber == 0) {
        //    if (articles[0].dataset.id == featuredArticle.dataset.id) {
        //        articles[0].remove();
        //    } else {
        //        articles[9].remove();
        //    }
        //}
        //BlogHolder.innerHTML = "";
        //for (let article of articles) {
        //    BlogHolder.append(article);
        //}
        //if (articles.length % 9 != 0) {
        //    BlogBtn.style.opacity = .5;
        //    BlogBtn.disabled = true;
        //} else {
        //    BlogBtn.style.opacity = 1;
        //    BlogBtn.disabled = false;
        //}

    }

    const filter = document.querySelector('#story-filter');

    filter.addEventListener('change', () => {
        getMoreArticles(0, 9, filter.value);
        BlogBtn.dataset.page = 1;
    })

    BlogBtn.addEventListener('click', () => {
        getMoreArticles(BlogBtn.dataset.page, 9, filter.value, true);
    })

    ////Filters
    //document.addEventListener("click", function (e) {

    //    //Load More Articles Button
    //    if (e.target.matches(".js-load-more")) {
    //        e.target.setAttribute("disabled", "");
    //        getMoreArticles(BlogBtn.getAttribute("data-page"), 1);
    //    }

    //    const blogSection = document.querySelector('.tile-area.blog-section');
    //    if (!e.target.matches(".js-blog-filter"))
    //        return;

    //    e.preventDefault();

    //    async function getSortedArticles(category) {
    //        const data = await fetch(`/newsroom/${category}`)
    //        if (data.ok) {
    //            const results = await data.text();
    //            console.log(results);
    //            blogSection.innerHTML = results;
    //            if (blogSection.querySelector('.content').childElementCount == 0) {
    //                blogSection.innerHTML = "<h2 class='f-h2' style='text-align: center;'>There are no articles that match that category</h2>";
    //            }
    //        }

    //    }

    //    if (!e.target.classList.contains('active')) {
    //        console.log('Fetching Articles: ' + e.target.dataset.cat)
    //        getSortedArticles(e.target.dataset.cat);
    //    }

    //    //Set Filters
    //    var topics = document.querySelectorAll(".js-blog-filter");
    //    topics.forEach(function (topic) {
    //        topic.classList.remove("active");
    //    });
    //    e.target.classList.add("active");

    //    ////Filter Articles
    //    //var articles = document.querySelectorAll(".blog-article");

    //    //articles.forEach(function (article) {
    //    //    if (e.target.getAttribute("data-topic") != "all" && article.getAttribute("data-topics").indexOf(e.target.getAttribute("data-topic")) == -1) {
    //    //        article.classList.add("hidden");
    //    //    }
    //    //    else {
    //    //        article.classList.remove("hidden");
    //    //    }
    //    //});
    //});

    //var topics = document.querySelectorAll(".js-blog-filter");
    //topics.forEach(function (topic) {
    //    if (window.location.href.indexOf("=" + encodeURI(topic.textContent)) != -1) {
    //        topic.click();
    //    }
    //});


    ////test if should preload category based on location hash
    //if (window.location.hash) {
    //    var hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character

    //    topics.forEach(function (topic) {
    //        if (topic.innerText.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "") == hash.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "")) {
    //            topic.click();
    //        }
    //    })
    //}
    document.addEventListener('DOMContentLoaded', () => {
        getMoreArticles(0, 9, "all");
    })
}

